// get Fake POST
export const GET_FAKE_POST = "GET_FAKE_POST";

// OPEN DIALOG

export const OPEN_FAKE_POST_DIALOG = "OPEN_FAKE_POST_DIALOG";

//  close fakePOST Dialog

export const CLOSE_FAKE_POST_DIALOG = "CLOSE_FAKE_POST_DIALOG";
// create fakePOST

export const CREATE_FAKE_POST = "CREATE_FAKE_POST";
// edit plan

export const EDIT_FAKE_POST = "EDIT_FAKE_POST";

// delete fakePOST

export const DELETE_FAKE_POST = "DELETE_FAKE_POST";
