// get complain
export const GET_COMPLAINT = "GET_COMPLAINT";

//solve complain
export const SOLVED_COMPLAINT = "SOLVED_COMPLAINT";

//dialog
export const OPEN_COMPLAINT_DIALOG = "OPEN_COMPLAINT_DIALOG";

export const CLOSE_COMPLAINT_DIALOG = "CLOSE_COMPLAINT_DIALOG";
