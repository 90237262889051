// get Fake User
export const GET_FAKE_USER = "GET_FAKE_USER";

// OPEN DIALOG

export const OPEN_FAKE_USER_DIALOG = "OPEN_FAKE_USER_DIALOG";

//  close fakeUser Dialog

export const CLOSE_FAKE_USER_DIALOG = "CLOSE_FAKE_USER_DIALOG";
// create fakeUser

export const CREATE_FAKE_USER = "CREATE_FAKE_USER";
// edit plan

export const EDIT_FAKE_USER = "EDIT_FAKE_USER";

// delete fakeUser

export const DELETE_FAKE_USER = "DELETE_FAKE_USER";

// fakeUser Live Switch
export const LIVE_SWITCH = "LIVE_SWITCH";
