// get_ banner

export const GET_BANNER = "GET_BANNER";

// banner dialog open
export const OPEN_BANNER = "OPEN_BANNER";

// close banner
export const CLOSE_BANNER = "CLOSE_BANNER";

//  create banner
export const CREATE_BANNER = "CREATE_BANNER";

// update banner
export const UPDATE_BANNER = "UPDATE_BANNER";

// delete banner
export const DELETE_BANNER = "DELETE_BANNER";
