// get plan
export const GET_COIN_PLAN = "GET_COIN_PLAN";

// OPEN DIALOG

export const OPEN_COIN_PLAN_DIALOG = "OPEN_COIN_PLAN_DIALOG";

//  close CoinPlan Dialog

export const CLOSE_COIN_PLAN_DIALOG = "CLOSE_COIN_PLAN_DIALOG";
// create CoinPlan

export const CREATE_COIN_PLAN = "CREATE_COIN_PLAN";
// edit plan

export const EDIT_COIN_PLAN = "EDIT_COIN_PLAN";

// delete CoinPlan

export const DELETE_COIN_PLAN = "DELETE_COIN_PLAN";

// coinPlan Active Switch

export const ACTIVE_SWITCH = "ACTIVE_SWITCH";


export const PURCHASE_HISTORY = "PURCHASE_HISTORY";

